<template>
  <div class="social-login">
    <div class="social-heading">
      <p>{{ $t("connect with") }}</p>
    </div>
    <div class="social-icons-container">
      <div class="social-icon">
        <img @click="facebookLogin" :src="fbIcon" alt />
      </div>
      <div class="social-icon">
        <img @click="googleSignIn" id="Google-SignIn" :src="gIcon" alt />
      </div>
      <!-- <div class="social-icon">
        <img id="Apple-signIn" :src="appleIcon" alt />
      </div>-->
    </div>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";
import { _googleId } from "../../../../provider-config.js";
import Utility from "@/mixins/Utility.js";
import { mapGetters, mapActions, mapMutations } from "vuex";
import snackbar from "node-snackbar";
import "../../../../../node_modules/node-snackbar/dist/snackbar.css"
import { showSnackBar } from '../../../../utilities.js';

// /node_modules/node-snackbar/dist/snackbar.css";



export default {
  data() {
    return {
      fbIcon: require("@/assets/icons/facebook.svg"),
      appleIcon: require("@/assets/icons/Apple.svg"),
      gIcon: require("@/assets/icons/google.svg"),
      type: null,
    };
  },
  mounted() {
    //this.googleSignIn();
    // this.appleSignin();

    eventBus.$on("formType", (value) => {
      this.type = value;
    });
  },

  computed: {
    ...mapGetters(["country", "profileid"]),
  },

  created() {
    eventBus.$on("login-response-social-profile", (data) => {
      console.log("THE SOCIAL LOGIN RESPONSE FROM COMPONENT ---- > ", data);
				if (this.appConfig.featureEnabled.isMultipleProfileEnabled) {
					this.multiProfileCheck();
				}
    });

    if (this.appConfig.featureEnabled.isMultipleProfileEnabled) {
      eventBus.$on("subscriberDetails-set-success", (data) => {

        if(!data.autologin) {
 this.multiProfileCheck();         
        }
      });
    }
    eventBus.$on("social-login-failed", (data) => {
      console.log("SOCIAL LOGIN ERROR ---- > ", data);

      if(data.errorcode) {
        // snackbar.show({
        //   text: this.$t(data.reason),
        //   textColor: "#ffffff",
        //   pos: "bottom-center",
        //   actionTextColor: "#de2600",
        // });

        showSnackBar(this.$t(data.reason));
      }
    });

    // if (!window.FB) {

    console.log("inside popup", window.facebookLoaded);
    window.dispatchEvent(window.facebookLoaded);
    // }
  },
  methods: {
    ...mapActions(["listProfiles"]),

    ...mapMutations(["setProfileid"]),

    facebookLogin() {
      let self = this;
			let coupon_campaign = localStorage.getItem('campaign-coupon');
      /* eslint-disable */
      FB.login(
        function(response) {
          if (response.authResponse) {
            let payload = {
              app: "FB",
              type: self.type ? self.type : "lookup",
              params: {
                devicetype: "PC",
                fbtoken: response.authResponse.accessToken,
                deviceos: self.getDeviceOS(),
                country: self.country ? self.country.CountryCode : "",
								couponcode: coupon_campaign
              },
            };
            eventBus.$emit("subscriberLogin", payload);
          }
        },
        { scope: "public_profile,email" }
      );
    },
    googleSignIn() {
        const client = google.accounts.oauth2.initCodeClient({
          client_id: _googleId,
          scope: 'email profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
          ux_mode: 'popup',
          callback: (response) => this.handleGoogleAuthResponse(response), // Use an arrow function here
        });
  
        client.requestCode();
      },
  
      handleGoogleAuthResponse(response) {
				let coupon_campaign = localStorage.getItem('campaign-coupon');
        const idToken = response.id_token;
        const xhr = new XMLHttpRequest();
        xhr.open('POST', 'https://www.googleapis.com/oauth2/v4/token', true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
  
        // Set up the request body with the correct parameters, including client_secret
        const requestBody = `code=${response.code}&grant_type=authorization_code&client_id=${_googleId}&client_secret=iwYUYIH-QtlqU_r6MgIRjL5o&redirect_uri=${window.location.origin}`;
        xhr.onload = () => {
          const tokenResponse = JSON.parse(xhr.responseText);
          const accessToken = tokenResponse.id_token;
  
          const payload = {
            app: "Google",
            type: this.type ? this.type : "lookup",
            params: {
              devicetype: "PC",
              deviceos: this.getDeviceOS(),
              gtoken: accessToken,
              country: this.country ? this.country.CountryCode : "",
              couponcode: coupon_campaign
            },
          };
  
          // Emit an event to send the payload to the server
          eventBus.$emit("subscriberLogin", payload);
        };
  
        xhr.send(requestBody);
      },
    appleSignin() {
      /* eslint-disable */
      AppleID.auth.init({
        clientId: "com.mobiotics.web.vlive",
        scope: "name email",
        redirectURI: "https://vlivewebapp-5ee2b.firebaseapp.com/",
        state: "qvlmvpyqBmyNx0x7MvBJ3DG7obdbUZ",
        usePopup: true, //or false defaults to false
      });
      let element = document.getElementById("Apple-signIn");
      element.addEventListener("click", function() {
        /* eslint-disable */
        AppleID.auth.signIn().then(
          (response) => {
            if (response.authorization) {
              let payload = {
                app: "Apple",
                params: {
                  devicetype: "PC",
                  deviceos: self.getDeviceOS(),
                  appletoken: response.authorization.id_token,
                },
              };
              eventBus.$emit("subscriberLogin", payload);
            }
          },
          (err) => {
            console.log(err);
          }
        );
      });
    },
		multiProfileCheck(){
		this.listProfiles().then((profileResponse) => {
		if (profileResponse.data.reason) {
			// this.formError = this.$t(profileResponse.data.reason);
		} else {

			profileResponse.data.data.forEach((profile, index) => {
				if (profile.profileid === this.profileid) {
					localStorage.setItem("currentProfileId", profile.profileid);
					localStorage.setItem("subscriberProfileDetails", JSON.stringify(profile));

					this.setProfileid(profile.profileid);
				}
			});
			if (profileResponse.data.totalcount > 1) {
				//this.$router.push("/switchProfile");
				let currentLanguage = this.getCurrentLanguageUrlBase();
				if (sessionStorage.getItem("setPlanDeeplinkPath")) {
           location.href = location.origin + "/" + currentLanguage +"/switchProfile?reload=false"
				} else {
						this.$router.push({name: "switchProfile", params: { lang: currentLanguage }})
				}					 
			} else {
				if(profileResponse && profileResponse.data && profileResponse.data.data) { 

					let profileData = profileResponse.data.data;
					localStorage.setItem("currentProfileId", profileData[0].profileid);
					localStorage.setItem("subscriberProfileDetails", JSON.stringify(profileData[0]));

					this.setProfileid(profileData[0].profileid);
				}
			}
		}
	});
		},
  },

  beforeDestroy() {
    eventBus.$off("login-response-social-profile");
    eventBus.$off("subscriberDetails-set-success");
  },

  mixins: [Utility],
};
</script>

<style lang="scss">
@import "@/sass/_variables.scss";
.social-login {
  background-color: #1a1a1a;
  padding: 3.6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 0.6rem;
  border-top-left-radius: 0.6rem;
  .social-heading {
    color: #868688;
    font-family: $font-regular;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 32px;
    padding-bottom: 0.8rem;
  }
  .social-icons-container {
    display: flex;
    margin-left: 20px;
    margin-top: 10px;
    .social-icon {
      padding-right: 1.4rem;
      transition: all 0.3s ease-in-out;
      cursor: pointer;
      img {
        height: 54px;
        width: 54px;
      }
      &:hover {
        transform: scale(1.15);
      }
    }
  }
}

//extra small screens.
@media only screen and (max-width: 576px) {
  .social-login {
    padding: 2.6rem;
    .social-heading {
      font-size: 20px;
    }
    .social-icons-container {
      .social-icon {
        img {
          height: 45px;
          width: 45px;
        }
        &:hover {
          transform: none;
        }
      }
    }
  }
}
</style>
